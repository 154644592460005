import { HttpClientService } from './../../http-client/http-client.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private httpService: HttpClientService) { }

  login(payload: any): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/common/login', payload);
  }

  loginViaYosync(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/login/initiate', "");
  }

  createAccesstoken(requestId: string): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/login/create_access_token/' + requestId, "");
  }

  logout(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/login/logout', "");
  }

  frontendURL(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/frontend-url', "");
  }

  refreshToken(payload: { refresh_token: any; }): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/common/token/refresh', payload);
  }

  getProduct(): Observable<any> {
    return this.httpService.post(environment.BASE_URL + '/v1/product/list', { headers: { 'content-type': 'application/json' } });
  }

  getProductDetail(productId: any): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/product/view/' + productId, { headers: { 'content-type': 'application/json' } });
  }

  getcategory(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/category/list', { headers: { 'content-type': 'application/json' } });
  }

  getTrendProduct(): Observable<any> {
    return this.httpService.get(environment.BASE_URL + '/v1/product/trend', "");
  }

}
