import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth/auth.service';
import { AuthDataService } from './core/services/data/auth-data/auth-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'artyosync';
  subscriptions: Subscription[] = [];
  userId: any;

  miniSeacrhHide = [
    'store/user-profile',
    'login'
  ]

  constructor(
    private router: Router,
    private authState: AuthDataService,
    private auth: AuthService

  ) {
    const routes = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.userId = this.auth.getUserId();
        if (event.url.replace('/', '') === 'store') {
          this.authState.setHeaderStatus(true);
          this.authState.setMiniSearch(false);
          this.authState.setSearchResult(false);
          this.authState.setLogoFullHeight(false);
        }
        else if (event.url.split("?").includes('/store/meta-form')) {
          this.authState.setHeaderStatus(false);
          this.authState.setMiniSearch(true);
          this.authState.setSearchResult(false);
          this.authState.setLogoFullHeight(true);
        }
        else if(event.url.split("?").includes('/store/search-result')) {
          this.authState.setHeaderStatus(false);
          this.authState.setMiniSearch(true);
          this.authState.setSearchResult(true);
          this.authState.setLogoFullHeight(false);
        } else if(this.miniSeacrhHide.includes(event.urlAfterRedirects.replace('/', ''))) {
            this.authState.setHeaderStatus(false);
            this.authState.setMiniSearch(false);
            this.authState.setSearchResult(false);
            this.authState.setLogoFullHeight(false);
        } else {
          this.authState.setHeaderStatus(false);
          this.authState.setMiniSearch(true);
          this.authState.setSearchResult(false);
          this.authState.setLogoFullHeight(false);
        }
      }
    });
    this.setSubscription(routes);
  }

  setSubscription(request: Subscription): void {
    this.subscriptions.push(request);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
