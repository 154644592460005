import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {


  constructor(private http: HttpClient) { }

  getHeader(headers?: any) {
    let header = {
      'Content-Encoding': 'br'
    }

    if (headers) {
      Object.assign(header, headers['headers']);
    }

    return new HttpHeaders(header);
  }

  get(url: string, headers?: any) {
    return this.http.get(url, { headers: this.getHeader(headers) });
  }

  getBlob(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  post(url: string, data: any, headers?: any) {
    return this.http.post(url, data, { headers: this.getHeader(headers) });
  }

  postWithProgress(url: string, data: any, headers?: any) {
    return this.http.post(url, data, { headers: this.getHeader(headers), reportProgress: true,
      observe: 'events' });
  }

  put(url: string, data: any, headers?: any) {
    return this.http.put(url, data, { headers: this.getHeader(headers) });
  }

  delete(url: string, data: any, headers?: any) {
    return this.http.request('delete', url, { body: data, headers: this.getHeader(headers) })
  }

}
