import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonStorageService } from '../../storage/common-storage.service';
import { DataStateService } from '../data-state.service';
import { ReferenceName as refName } from '../../../../shared/enum/reference-name.enum';

interface AuthState {
  loggedIn: boolean;
  userInfo: any;
  headerStatus: boolean;
  miniSearch: boolean;
  searchResult: boolean;
  isLogoFullHeight: boolean;
  selectedMetaInfo: object;
  frontEndURL: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthDataService extends DataStateService<AuthState>  {

  loggedInStatus$: Observable<boolean>;
  miniSearch$: Observable<boolean>;
  searchResult$: Observable<boolean>;
  isLogoFullHeight$: Observable<boolean>;

  constructor(private storage: CommonStorageService,) {
    super();
    const initialState: AuthState = {
      loggedIn: false,
      userInfo: [],
      headerStatus: false,
      miniSearch: false,
      searchResult: false,
      isLogoFullHeight: false,
      frontEndURL: '',
      selectedMetaInfo: {
        'image_type': '',
        'image_id': '',
        'data_entry_type': '',
        'meta_info': ''
      }
    };
    this.state$ = new BehaviorSubject<AuthState>(initialState);

    this.loggedInStatus$ = this.select('loggedIn');
    this.miniSearch$ = this.select('miniSearch');
    this.searchResult$ = this.select('searchResult');
    this.isLogoFullHeight$ = this.select('isLogoFullHeight');
  }

  setLoggedInStatus(status: boolean): void {
    this.setState({ loggedIn: status });
  }

  setUserProfileInfo(userInfo: any): void {
    this.setState({ userInfo: userInfo });
  }

  getUserProfileInfo() {
    return this.selectSnapshot('userInfo');
  }

  setHeaderStatus(status: boolean) {
    this.setState({ headerStatus: status })
  }

  setMiniSearch(status: boolean) {
    this.setState({ miniSearch: status });
  }

  setSearchResult(status: boolean) {
    this.setState({ searchResult: status });
  }

  setLogoFullHeight(isLogoFullHeight: boolean) {
    this.setState({ isLogoFullHeight: isLogoFullHeight });
  }

  getHeaderStatus() {
    return this.selectSnapshot('headerStatus');
  }

  setFrontEndURL(url: string): void {
    this.setState({ frontEndURL: url });
    this.storage.set(refName.FRONTEND_URL, url);
  }

  getFrontEndURL() {
    return this.storage.get(refName.FRONTEND_URL);
  }

  setSelectedMetaData(data: object) {
    this.setState({ selectedMetaInfo: data });
    this.storage.set(refName.SELECTED_META_INFO, data);
  }

  getSelectedMetaData() {
    return this.storage.get(refName.SELECTED_META_INFO);
  }
}
